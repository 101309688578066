<template>
	<v-card tile light flat class="car-details grey lighten-4 rounded-lg pa-5">
		<h2 class="mb-5 template-color--text text--darken-2">Araç bilgileri</h2>
		<v-img contain max-height="300" :src="carImg"></v-img>
		<v-card-title class="px-0 car-title">
			{{ car.title }}
		</v-card-title>

		<h2>{{ car.salesPrice | tl({ avoidEmptyDecimals: '' }) }}</h2>

		<div>
			{{ car.kilometer | currency({ fractionCount: 0, symbol: '', thousandsSeparator: '.' }) }} KM |
			{{ car.fuelType }}
			|
			{{ car.transmissonType }}
		</div>

		<div v-if="$route.name !== 'optionComplete'">
			<div class="mt-5">
				<span class="font-weight-bold"> Opsiyonlama süresi : </span>
				{{ expireHour }} saat
			</div>
			<div>
				<span class="font-weight-bold"> Opsiyonlama bedeli : </span>
				{{ optionPrice | tl({ avoidEmptyDecimals: '' }) }}
			</div>
			<div>
				<span class="font-weight-bold"> Kalan tutar : </span>
				{{ (car.salesPrice - optionPrice) | tl({ avoidEmptyDecimals: '' }) }}
			</div>
			<div v-if="$route.name === 'optionCustomerInfo'">
				<div class="mt-5">
					<a :href="getSite.carReservationSettings?.configs.kvkkFile.name" target="_blank" @click.stop
						>KVKK Aydınlatma Metnini</a
					>
					okudum, kişisel verilerimin yukarıda yer alan prensipler uyarınca işlendiğini anladım.
				</div>
				<div>
					<v-checkbox
						color="template-color darken-2"
						dense
						hide-details="auto"
						v-model="approveContact"
						:error-messages="approvedErrors()"
					>
						<template v-slot:label>
							{{ getSite.companyName }}
							tarafından E-posta ve SMS yoluyla ticari elektronik ileti almayı kabul ediyorum.
						</template>
					</v-checkbox>
				</div>
			</div>
			<div class="my-5">
				<v-btn block class="text-capitalize" dark color="template-color" @click="continueProcess">{{
					continueProcessButtonText
				}}</v-btn>
			</div>
		</div>
	</v-card>
</template>

<script>
import EventBus from '@/event-bus';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { sameAs } from 'vuelidate/lib/validators';

export default {
	name: 'ReservationCarDetails',
	mixins: [validationMixin],
	props: {
		carValue: {
			type: Object,
			required: true,
		},
		settingsValue: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			approveContact: false,
		};
	},
	validations: {
		approveContact: { sameAs: sameAs(() => true) },
	},
	computed: {
		...mapGetters({
			getSite: 'getSite',
		}),
		car() {
			return this.carValue;
		},
		carImg() {
			return this.car?.photos.length > 0
				? this.car.photos[0]
				: // eslint-disable-next-line global-require
				  require('../../../../../../assets/images/no-photo.svg');
		},
		optionPrice() {
			if (this.settingsValue.configs.optionPrice !== null) return this.settingsValue.configs.optionPrice;
			if (this.settingsValue.configs.optionRate !== null)
				return (this.car.salesPrice * this.settingsValue.configs.optionRate) / 100;

			return 0;
		},
		expireHour() {
			return this.settingsValue.configs.expireHour;
		},
		continueProcessButtonText() {
			if (this.$route.name === 'optionStart') return 'Devam Et';
			if (this.$route.name === 'optionCustomerInfo') return 'Ödemeyi Tamamla';

			return null;
		},
	},
	methods: {
		approvedErrors() {
			const errors = [];
			const { $dirty, sameAs } = this.$v.approveContact;
			if (!$dirty) return errors;
			// eslint-disable-next-line no-unused-expressions
			!sameAs && errors.push('aydınlatma metnini onaylamanız gerekiyor.');
			return errors;
		},
		continueProcess() {
			if (this.$route.name === 'optionCustomerInfo') {
				EventBus.$emit('reserve-car', this.$v.approveContact);
				return;
			}

			this.$router.push({ name: 'optionCustomerInfo', params: { 'car-value': this.car } });
		},
	},
};
</script>

<style lang="scss" scoped>
.car-details {
	.car-title {
		word-break: normal;
	}
}
</style>
